import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AbilityModule } from '@casl/angular';
import { AuthInterceptor } from '../../../interceptors/auth.interceptor';
//import { Interceptor } from 'app/interceptors/auth.interceptor';
import { PostCommentComponent } from '../comment/comment.component';
import { ConversationComponent } from './conversation.component';
import { ConversationService } from './conversation.service';

@NgModule({
  declarations: [ConversationComponent, PostCommentComponent],
  imports: [
    AbilityModule,
    CommonModule,
    FlexLayoutModule,
    FormsModule,

    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatToolbarModule,
    MatExpansionModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatMenuModule,
  ],
  providers: [
    ConversationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  exports: [ConversationComponent],
})
export class PostConversationModule {}
